import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { useState } from 'react'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import ChatBot from 'views/tools/chat-bot'
import { View } from 'components/lib'
import { Provider } from 'react-redux'
import {
  BackendFetch,
  DevTools,
  LanguageDetector,
  LanguageStorage,
  Tolgee,
  TolgeeProvider,
} from '@tolgee/react'
import { useWindowDimensions } from 'components/hooks/window'
import { appSupportedLanguages } from 'helpers'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from './store'
import '../css/output.css'

const tolgee = Tolgee()
  .use(DevTools())
  .use(LanguageDetector())
  .use(LanguageStorage())
  .use(BackendFetch())
  .init({
    availableLanguages: appSupportedLanguages.map((locale) => locale.value),
    projectId: '2',
    fallbackLanguage: 'en',
    defaultLanguage: 'en',
  })

const ChatBotMain = ({
  chatBotName,
  position,
  chatWindowHeight,
  chatWindowWidth,
  widget = false,
}) => {
  const [initializing, setInitializing] = useState(false)
  const [visible, setIsVisible] = useState(false)
  const [chatIconSizes, setChatIconSizes] = useState({ width: '', height: '' })
  const { width, height: deviceHeight } = useWindowDimensions()

  const md = width < 1021

  const adjustChatPosition = md && visible ? 0 : '15px'

  const positioning = {
    'bottom-right': {
      bottom: '-4px',
      right: adjustChatPosition,
    },
    'bottom-left': {
      bottom: '-4px',
      left: adjustChatPosition,
    },
    'bottom-center': {
      bottom: '-4px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    'top-left': {
      top: adjustChatPosition,
      left: adjustChatPosition,
    },
    'top-right': {
      top: adjustChatPosition,
      right: adjustChatPosition,
    },
    'top-center': {
      top: adjustChatPosition,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    middle: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    'middle-right': {
      top: '50%',
      right: adjustChatPosition,
      transform: 'translateY(-50%)',
    },
    'middle-left': {
      top: '50%',
      left: adjustChatPosition,
      transform: 'translateY(-50%)',
    },
  }

  const adjustChatViewHeight = () => {
    if (deviceHeight < chatWindowHeight) {
      return deviceHeight
    } else {
      return chatWindowHeight
    }
  }

  return (
    <div
      id='eleo-chatbot-content'
      style={{
        position: 'fixed',
        backgroundColor: 'transparent',
        ...positioning[position],
        boxShadow: visible ? '0 3px 10px rgb(0 0 0 / 0.2)' : 'none',
        width: md && visible ? '100%' : 'auto',
        zIndex: 999999999,
      }}
    >
      <Frame
        id='iframe'
        frameBorder='0'
        height={visible ? adjustChatViewHeight() : chatIconSizes.height}
        width={visible ? (md ? '100%' : chatWindowWidth) : md ? '100%' : chatIconSizes.width}
        style={{ colorScheme: 'normal', overflowX: 'hidden' }}
        contentDidMount={() => {
          setInitializing(true)
          setTimeout(() => {
            const iframe = document.getElementById('iframe')
            const dynamicStylesDiv = document.getElementById('eleo-chat-bot')

            if (iframe && dynamicStylesDiv) {
              const iframeDocument = iframe.contentDocument
              const headElement = iframeDocument.head

              // Clone the styles from the temp div
              const stylesClone = dynamicStylesDiv.cloneNode(true)

              // Append the cloned styles to the iframe head
              headElement.appendChild(stylesClone)
              // Remove only the style elements from the original temp div
              const styleElements = dynamicStylesDiv.querySelectorAll('style')
              styleElements.forEach((styleElement) => {
                dynamicStylesDiv.removeChild(styleElement)
              })
              setInitializing(false)
            }
          }, 2000)
        }}
      >
        <FrameContextConsumer>
          {(frameContext) => {
            frameContext.document.body.style.background = visible ? '#ffffff' : 'transparent'
            const cache = createCache({
              container: frameContext.document.head,
              key: 'my-key',
            })
            return (
              <CacheProvider value={cache}>
                <TolgeeProvider tolgee={tolgee} filesUrlPrefix='/'>
                  <Provider store={store}>
                    <BrowserRouter>
                      <Routes>
                        <Route
                          path='*'
                          element={
                            <div>
                              {!initializing && (
                                <View
                                  widget
                                  display={
                                    <ChatBot
                                      chatBotName={chatBotName}
                                      widget={widget}
                                      visible={visible}
                                      setIsVisible={setIsVisible}
                                      setChatIconSizes={setChatIconSizes}
                                    />
                                  }
                                ></View>
                              )}
                            </div>
                          }
                        ></Route>
                      </Routes>
                    </BrowserRouter>
                  </Provider>
                </TolgeeProvider>
              </CacheProvider>
            )
          }}
        </FrameContextConsumer>
      </Frame>
    </div>
  )
}

export default ChatBotMain
